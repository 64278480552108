
.about {
  // height: 16.24rem;
  // // overflow-y: auto;
  // position: relative;
  // // background-color: red;
  .test {
    width: 1.26rem;
    height: 1.26rem;
    background-color: red;
    // position: absolute;
    // // bottom: calc(1.16rem + 692px - 568px);
    // bottom: 1.16rem;
    // left: 50%;
    // transform: translateX(-50%);
  }
  .img {
    width: 100%;
    display: block;
    height: auto;
  }
}
